@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-brand: 79 70 229; /* indigo-600 */
  --color-brand-light: 99 102 241; /* indigo-500 */
  --color-brand-dark: 67 56 202; /* indigo-700 */
}

@layer base {
  html {
    @apply w-full h-full antialiased text-gray-900 bg-white dark:bg-gray-900 dark:text-gray-300;
  }

  body {
    @apply m-0 w-full h-full;
  }
}

@layer components {
  .heading-1 {
    @apply text-4xl sm:text-5xl lg:text-6xl font-bold tracking-tight;
  }

  .heading-2 {
    @apply text-3xl sm:text-4xl font-bold tracking-tight;
  }

  .section-container {
    @apply max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-16 lg:py-20;
  }

  .btn-primary {
    @apply px-6 py-3 rounded-lg bg-brand text-white font-semibold shadow-lg
           hover:bg-brand-dark transition-colors duration-200
           focus:outline-none focus:ring-2 focus:ring-brand focus:ring-offset-2;
  }

  .btn-secondary {
    @apply px-6 py-3 rounded-lg bg-white text-brand font-semibold shadow-lg
           border border-brand/10 hover:bg-gray-50 transition-colors duration-200
           focus:outline-none focus:ring-2 focus:ring-brand focus:ring-offset-2
           dark:bg-gray-800 dark:hover:bg-gray-700;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.animation-delay-250 {
  animation-delay: 250ms;
}

.animation-delay-500 {
  animation-delay: 500ms;
}

.animation-delay-750 {
  animation-delay: 750ms;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

input::placeholder,
textarea::placeholder {
  color: #c4c4c4;
  font-size: 16px;
  font-weight: 300;
}

.placeholder {
  padding-left: 8px;
  color: #c4c4c4;
  font-size: 16px;
  font-weight: 300;
}

/* selector for data-testid="input" */
div[data-testid="language"] ul {
  bottom: 36px;
}

.shadow-avatar {
  box-shadow: 0px 4px 4px 0px #00000040;
}

.shadow-story-card {
  box-shadow: 0px 4px 14px 0px #0000001a;
}

.admin-layout {
  font-family: "Roboto", sans-serif;
}

.admin-layout input,
.admin-layout textarea {
  background-color: #ffffff !important;
  border: 1px solid #d1d5db;
  color: #000000;
  font-size: 16px;
}

.admin-layout input:disabled,
.admin-layout textarea:disabled {
  background-color: #d1d5db !important;
  border: 1px solid #d1d5db;
  color: #000000;
  font-size: 16px;
}

.admin-layout input::placeholder,
.admin-layout textarea::placeholder {
  color: #c4c4c4;
  font-size: 16px;
  font-weight: 500;
}

.admin-layout label {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}

.admin-layout-dropdown span {
  font-size: 16px;
  font-weight: 500;
}

.debugger-font {
  font-family: "Source Code Pro", sans-serif;
}

.ribbon {
  font-size: 28px;
  font-weight: bold;
  color: #fff;
}

.ribbon {
  --r: 0.8em;
  padding-inline: calc(var(--r) + 0.3em);
  line-height: 1.8;
  clip-path: polygon(0 0, 100% 0, calc(100% - var(--r)) 50%, 100% 100%, 0 100%, var(--r) 50%);
  width: fit-content;
}

.shadow-page {
  box-shadow:
    0 3px 2px -1px #00000040,
    inset 100px 0px 100px -50px #fdf2d5,
    inset -100px 0px 100px -50px #fdf2d5;
}

.shadow-avatar-bar {
  box-shadow: 0px 0px 24px 0px #00000040;
}

.shadow-avatar-bar-tab {
  box-shadow: 0px -6px 12px -2px #00000040;
}

@keyframes soundWave {
  0% {
    transform: scaleY(0.5);
    opacity: 0.5;
  }
  100% {
    transform: scaleY(1.5);
    opacity: 1;
  }
}
